<template lang="pug">
.text-center
  .top-dropdown
    v-menu(v-model="isMenuVisible", offset-y, :close-on-content-click="false")
      template(v-slot:activator="{ on, attrs }")
        div(
          :style="{ fontSize: '18px', fontWeight: 700, color: '#002442', height: '100%', width: 'auto', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }",
          v-bind="attrs",
          v-on="on"
        ) {{ selectedNode.path ? selectedNode.path : selectedNode.name }}
          v-icon(right, color="#002442") mdi-chevron-down
      node-list(
        :nodes="treeData",
        :selectedNode="selectedNode",
        @leaf-node-clicked="leafNodeClicked",
        ref="nodeList"
      )
</template>

<script>
import { getSeasonSegmentTree } from "@/api/league";
import NodeList from "./NodeList";

export default {
  name: "SeasonSegmentSelector",
  components: {
    NodeList,
  },
  props: {
    leagueId: {
      type: Number,
      require: true,
    },
    seasonSegmentId: {
      type: Number,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      treeData: [],
      isMenuVisible: false,
      selectedNode: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    async initData() {
      await this.getSeasonSegmentTreeApi();
      if (this.seasonSegmentId) this.selectSpecifiedNode();
      else this.selectFirstChild();
    },
    async getSeasonSegmentTreeApi() {
      const params = { league_id: this.leagueId };
      const response = await getSeasonSegmentTree(params);
      this.treeData = response.data;
    },
    selectFirstChild() {
      if (!(this.treeData && this.treeData.length > 0)) return;

      let leafNode = undefined;
      let firstChild = this.treeData[0];
      while (leafNode === undefined) {
        if (
          !firstChild.is_leaf &&
          firstChild.children &&
          firstChild.children.length > 0
        )
          firstChild = firstChild.children[0];
        else leafNode = firstChild;
      }
      this.leafNodeClicked(leafNode);
    },
    selectSpecifiedNode() {
      this.selectedNode = this.findNode(this.treeData, this.seasonSegmentId);
    },
    findNode(nodes, targetNodeId) {
      if (!nodes) return null; // Node not found

      for (let node of nodes) {
        if (node.is_leaf === true && node.id === targetNodeId) return node;
        if (node.children && node.children.length > 0) {
          const foundNode = this.findNode(node.children, targetNodeId);
          if (foundNode) return foundNode;
        }
      }

      return null; // Node not found
    },
    leafNodeClicked(node) {
      this.isMenuVisible = false;
      this.selectedNode = node;
      this.$emit("leaf-node-clicked", node);
    },
  },
};
</script>

<style lang="scss" scoped></style>
