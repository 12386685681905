<template lang="pug">
v-list(dense)
  template(v-for="(node, index) in nodes")
    v-list-group(
      v-if="node.children && node.children.length",
      :sub-group="node.level !== 0",
      :value="pathList[node.level] === node.name",
      ref="listGroup"
    )
      template(v-slot:activator)
        v-list-item-content
          v-list-item-title(v-text="node.name")
      node-list(
        :nodes="node.children",
        :selectedNode="selectedNode",
        v-on="$listeners"
      )
    v-list-item(
      v-else,
      link,
      @click="selectNode(node)",
      :style="{ paddingLeft: (node.level + 1) * paddingLeftDifference + 'px' }",
      :ref="'listItem' + node.id"
    )
      v-list-item-title(v-text="node.name")
</template>

<script>
export default {
  name: "NodeList",
  components: {},
  props: {
    nodes: {
      type: Array,
      required: true,
    },
    selectedNode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paddingLeftDifference: 24,
    };
  },
  computed: {
    pathList() {
      return this.selectedNode.path.split(" / ");
    },
  },
  watch: {},
  created() {
    this.initData();
  },
  mounted() {
    if (this.nodes[0].level < 1 || !this.$refs.listGroup) return;
    this.$refs.listGroup.forEach((listGroupVue) => {
      const headerElement = listGroupVue.$children[0].$el;
      headerElement.style.paddingLeft = `${
        this.paddingLeftDifference * this.nodes[0].level
      }px`;
    });
  },
  methods: {
    initData() {},
    selectNode(node) {
      if (node.is_leaf === false) return;
      this.$emit("leaf-node-clicked", node);
    },
    triggerClick(seasonSegmentId) {
      const ref = "listItem" + seasonSegmentId;
      const listItemElement = this.$refs[ref][0];
      console.log(listItemElement);
      if (listItemElement) listItemElement.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep
  div.v-list-group
  > div.v-list-group__header.v-list-item--active
  > div.v-list-group__header__prepend-icon
  > span.v-icon {
  transform: rotate(-180deg);
}
::v-deep
  div.v-list-group
  > div.v-list-group__header
  > div.v-list-group__header__prepend-icon
  > span.v-icon {
  transform: rotate(0deg);
}
</style>
